import React, { FC, memo, useEffect, useMemo, useRef, useState } from 'react'
import FlexingContainer from '@sport1/news-styleguide/FlexingContainer'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { ActionButtonProps, TeaserProps, TagProps, LabelProps } from '@sport1/types/web'
import Button from '@sport1/news-styleguide/Button'
import Grid from '@sport1/react-elements/Grid'
import { spacings } from '@sport1/news-styleguide/spacings'
import CardHeadline from '@sport1/news-styleguide/CardHeadline'
import WrappedNewsTickerLine from '../WrappedNewsTickerLine'
import { createNewsHref } from '@/utils/navigation/Navigator'
import { useNavigation } from '@/utils/navigation/Standard'
import { publishDateInTimeOrDate } from '@/helpers/dateHelper'
import nofollowExternal from '@/utils/url/nofollowExternal'
import { takeLastTagWithKeys } from '@/helpers/tagsHelper'

type Props = {
    content: TeaserProps[]
    actionButtons?: ActionButtonProps[]
    tags?: (TagProps | LabelProps)[]
    hasHeadline?: boolean
}

const NewsFeed: FC<Props> = ({ content, actionButtons, tags, hasHeadline }) => {
    const { navigation } = useNavigation()
    const newsFeedRef = useRef<HTMLDivElement | null>(null)
    const [isNewsFeedFirstSideElement, setIsNewsFeedFirstSideElement] = useState(false)
    const newsHref = useMemo(
        () =>
            createNewsHref({
                currentPath:
                    (tags && tags.length > 1 && tags[tags.length - 1]?.href) ||
                    navigation?.tag?.href,
            }),
        [navigation?.tag?.href, tags]
    )
    useEffect(() => {
        setIsNewsFeedFirstSideElement(!newsFeedRef?.current?.parentElement?.previousSibling)
    }, [])
    return (
        <Grid
            testID="news-feed"
            ref={newsFeedRef}
            marginTop={
                isNewsFeedFirstSideElement && window.location.pathname.includes('news')
                    ? spacings['spacing-11']
                    : spacings['spacing-none']
            }
        >
            {hasHeadline && (
                <Grid marginBottom={spacings['spacing-6']}>
                    <CardHeadline
                        cardHeadlineType={['cardHeadlineS', 'cardHeadlineM', 'cardHeadlineS']}
                        rightIconName="scroll-right"
                        text="Newsticker"
                        href={newsHref}
                    />
                </Grid>
            )}
            {content?.map((item, index) => {
                const lastTag = takeLastTagWithKeys(item.tags, ['iconUrl', 'title'])
                return (
                    <NonFlexingContainer
                        key={`${item.title}-${item.componentKey}`}
                        marginTop={
                            index
                                ? ['spacing-2', 'spacing-2', 'spacing-2', 'spacing-6']
                                : 'spacing-none'
                        }
                    >
                        <WrappedNewsTickerLine
                            headline={`${item.title}`}
                            publishedAt={
                                item.publishedAt ? publishDateInTimeOrDate(item.publishedAt) : ''
                            }
                            href={item.url}
                            webRel={nofollowExternal}
                            discipline={lastTag?.title}
                        />
                    </NonFlexingContainer>
                )
            })}
            {actionButtons ? (
                <FlexingContainer
                    horizontal
                    center
                    marginTop={['spacing-4', 'spacing-4', 'spacing-4', 'spacing-5']}
                    testID="newsfeed-button-container"
                >
                    {actionButtons.map((item, index) => (
                        <NonFlexingContainer
                            key={`${item.title}-${index}`}
                            marginX={['spacing-none', 'spacing-none', 'auto']}
                            width="100%"
                        >
                            <Button
                                text={item.title}
                                href={newsHref}
                                expand
                                webRel={nofollowExternal}
                                paddingY="spacing-5"
                                paddingX="spacing-9"
                                buttonType="M"
                                buttonVariant="highlight"
                                testID="news-feed"
                            />
                        </NonFlexingContainer>
                    ))}
                </FlexingContainer>
            ) : null}
        </Grid>
    )
}

export default memo(NewsFeed)
