import React, { FC, memo, useCallback } from 'react'
import dynamic from 'next/dynamic'
import { ResultTeaserProps, TrackingDataProps } from '@sport1/types/web'
import { MatchClientModel } from '@sport1/types/sportData'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { IndexedComponent } from '@/utils/content/indexCompontentTypes'
import { MatchOverlayProvider } from '@/utils/overlay/MatchOverlayProvider'
import { mapTrackingData, useTracking } from '@/utils/tracking/TrackingProvider'

/**
 * CAVEAT & TODO: Throughout the component we talk about mobile and
 * tablet. But what we mean is narrow and wide. EG we want to use
 * "mobile" even in desktop devices, if the ResultTeaser is inside a
 * column...
 * Refactor!
 */

const SingleResultTeaserCard = dynamic(() => import('@/components/ResultTeaser/SingleCard'), {
    ssr: false,
})
const MultipleResultTeaserCards = dynamic(() => import('@/components/ResultTeaser/MultipleCards'), {
    ssr: false,
})

type Props = {
    componentData: ResultTeaserProps
    disableContentSpaceX?: boolean
    forceMobileView?: boolean
    isRightColumn?: boolean
    trackingInteractionLabel: string
}

export type TrackingCreationType = 'card' | 'slider'

export const ONE_MOBILE_CARD_WIDTH = 327
export const ONE_CARD_WIDTH = 352
export const DEFAULT_SLIDE_SPACE_WIDTH = 1041
export const SPACE_BETWEEN_CARDS = 16
export const NEXT_CARD_VISIBLE = 11

const MATCHES_PREVIEW_COUNT = 3

const ResultTeaser: FC<Props> = ({
    componentData,
    forceMobileView,
    disableContentSpaceX,
    isRightColumn,
    trackingInteractionLabel,
}) => {
    const { trackTeaserImpression, trackTeaserClick } = useTracking()
    const { content: cards, fragmentMeta } = componentData
    const resultTeaserIndex = (componentData as IndexedComponent).nthOfType

    const trackMatchCardImpression = useCallback(
        (
            match: MatchClientModel,
            positionInSlider: number,
            type: TrackingCreationType,
            tracking?: TrackingDataProps[]
        ) => {
            const mappedTrackingData = mapTrackingData(tracking || componentData.meta?.tracking)
            trackTeaserImpression({
                advertiser_id:
                    mappedTrackingData.advertiser_id ||
                    `${match.competition?.id?.replace('opta_', '')}`,
                campaign: 'result-teaser',
                creation: `result-teaser | ${type}`,
                format: 'manual |',
                target_url: mappedTrackingData.url || `${match.competition?.name}`,
                variant: `${resultTeaserIndex + 1} | ${positionInSlider + 1} | result-teaser`,
            })
        },
        [componentData.meta?.tracking, resultTeaserIndex, trackTeaserImpression]
    )

    const trackMatchCardClick = useCallback(
        (
            match: MatchClientModel,
            positionInSlider: number,
            type: TrackingCreationType,
            tracking?: TrackingDataProps[]
        ) => {
            const mappedTrackingData = mapTrackingData(tracking || componentData.meta?.tracking)
            trackTeaserClick({
                advertiser_id:
                    mappedTrackingData.advertiser_id ||
                    `${match.competition?.id?.replace('opta_', '')}`,
                campaign: 'result-teaser',
                creation: `result-teaser | ${type}`,
                format: 'manual |',
                target_url: mappedTrackingData.url || `${match.competition?.name}`,
                variant: `${resultTeaserIndex + 1} | ${positionInSlider + 1} | result-teaser`,
            })
        },
        [componentData.meta?.tracking, resultTeaserIndex, trackTeaserClick]
    )

    if (cards.length === 0) {
        return null
    }

    if (cards.length === 1) {
        return (
            <NonFlexingContainer testID="result-teaser">
                <SingleResultTeaserCard
                    disableContentSpaceX={disableContentSpaceX}
                    forceMobileView={forceMobileView}
                    matchesPreviewCount={MATCHES_PREVIEW_COUNT}
                    resultTeaserCard={cards[0]}
                    partnerButtonFragmentMeta={fragmentMeta}
                    trackMatchCardClick={trackMatchCardClick}
                    trackMatchCardImpression={trackMatchCardImpression}
                    trackingInteractionLabel={trackingInteractionLabel}
                />
            </NonFlexingContainer>
        )
    }

    return (
        <NonFlexingContainer testID="result-teaser">
            <MatchOverlayProvider top={isRightColumn ? 40 : 70}>
                <MultipleResultTeaserCards
                    componentData={componentData}
                    partnerButtonFragmentMeta={fragmentMeta}
                    disableContentSpaceX={disableContentSpaceX}
                    forceMobileView={forceMobileView}
                    matchesPreviewCount={MATCHES_PREVIEW_COUNT}
                    trackMatchCardClick={trackMatchCardClick}
                    trackMatchCardImpression={trackMatchCardImpression}
                    trackingInteractionLabel={trackingInteractionLabel}
                />
            </MatchOverlayProvider>
        </NonFlexingContainer>
    )
}

export default memo(ResultTeaser)
